import { X } from "react-feather";

export default function Modal({ open, onClose, children }) {
    return (
        //backdrop
        <div onClick={onClose}
            className={`fixed inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-gray/20" : "invisible bg-black/0"}`}>

            {/* modal */}

            <div
                onClick={(e) => e.stopPropagation()}
                className={`bg-gray-300 rounded-xl shadow p-6 transition-all ${open ? "scale-30 opacity-100" : "scale-50 opacity-0"}`}>

                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600">
                    <X />
                </button>

                {children}
            </div>
        </div>
    )
}